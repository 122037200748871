import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateShopChargeTax,
  clearCart,
  placeOrder,
} from "../../../Redux/Store/cartReducer";
import { logoutUser } from "../../../Redux/Store/userReducer";
import Lottie from 'react-lottie';
import loadingLottie from '../../../assets/others/track-order-loading.json'
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ManagePayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.user_data);

  const [paymentType, setPaymentType] = useState(null);
  const [notselected, setNotselected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rzLoading, setRzLoading] = useState(false);
  const [isScheduled, setIsScheduled] = useState(localStorage.getItem('is_schedule') == 'true' ? true : false);
  let schedule_date = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_date") : null;
  let schedule_time = localStorage.getItem('is_schedule') == 'true' ? localStorage.getItem("schedule_time") : null;
  const [delivery_charge, setDeliveryCharge] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [alert, setAlert] = useState(null);
  const [name, setName] = useState(user?.name ? user?.name : null);
  const [mobile, setMobile] = useState(user?.phone ? user?.phone : null);
  const [email, setEmail] = useState(user?.email ? user?.email : null);

  useEffect(() => {
    if (cartProducts?.items?.length > 0) {
      calculateCharges();
    } else {
      navigate("/cart");
    }
  }, []);

  const calculateCharges = () => {
    let items = cartProducts?.items;

    dispatch(calculateShopChargeTax({
      calculateCharge: true,
      delivery_lat: localStorage.getItem("userLat"),
      delivery_long: localStorage.getItem("userLng"),
      deliveryTip: 0,
      items: items,
      promocode: {
        "code": localStorage.getItem('coupon')
      },
      shopId: cartProducts.shop_id,
      user_address_id: localStorage.getItem("addressId")
    })).then((response) => {
      if (response?.payload?.message == 'success') {
        setDeliveryCharge(response?.payload?.result?.orders?.delivery_charge)
        if (response?.payload?.result?.promocode?.status == 'success') {
          setDiscount(parseFloat(response?.payload?.result?.promocode?.discount).toFixed(2))
        }
        if (!response?.payload?.result?.deliveryOptions?.isDeliverable) {
          setAlert(response?.payload?.result?.deliveryOptions?.message)
        } else {
          setAlert(null)
        }
        if (response?.payload?.result?.changes && !alert) {
          setAlert(response?.payload?.result?.changes[0])
        }
      }
      setLoading(false);
    });
  }

  const totalPrice = () => {
    let tax = 0;
    let surge_charge = 0;
    // if (cartProducts?.shop_tax?.success) {
    //   tax = cartProducts?.shop_tax?.tax > 0 ? ((parseFloat(cartProducts?.totalAmount) * parseFloat(cartProducts?.shop_tax?.tax)) / 100) : 0;
    //   surge_charge = cartProducts?.shop_tax?.surge_charge;
    // }
    let tip = 0;
    if (localStorage.getItem("tip") && parseFloat(localStorage.getItem("tip")) > 0) {
      tip = parseFloat(localStorage.getItem("tip"));
    } else {
      tip = 0;
    }

    let packing_charge = 0;
    // if (cartProducts?.shop_distance?.shop_id?.packing_charge && cartProducts?.shop_distance?.shop_id?.packing_charge > 0) {
    //   packing_charge = cartProducts?.shop_distance?.shop_id?.packing_charge;
    // }

    if (discount > 0) {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) - parseFloat(discount) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    } else {
      return (parseFloat(cartProducts.totalAmount + parseFloat(tip)) + parseFloat(tax) + parseFloat(surge_charge) + parseFloat(delivery_charge) + parseFloat(packing_charge)).toFixed(2)
    }
  }

  const _completeOrder = (e) => {
    if (paymentType == null) {
      setNotselected(true)
    } else {
      setLoading(true)
      e.preventDefault();

      let tip = 0;
      if (localStorage.getItem("tip") && parseFloat(localStorage.getItem("tip")) > 0) {
        tip = parseFloat(localStorage.getItem("tip"));
      } else {
        tip = 0;
      }

      let items = cartProducts?.items;

      dispatch(placeOrder({
        calculateCharge: false,
        delivery_charge: delivery_charge,
        delivery_lat: localStorage.getItem("userLat"),
        delivery_lng: localStorage.getItem("userLng"),
        deliveryTip: tip,
        items: items,
        notes: null,
        payment_mode: paymentType,
        promocode: {
          "code": localStorage.getItem('coupon'),
          "id": localStorage.getItem('couponId')
        },
        shopId: cartProducts.shop_id,
        user_address_id: localStorage.getItem("addressId")
      })).then((response) => {
        if (response && response.payload && response.payload?.message == "success") {
          if (response.payload?.result?.payment_mode == 'cash') {
            localStorage.removeItem("tip");
            dispatch(clearCart());

            navigate("/order-tracking/" + response.payload?.result?.order_id);
          } else {
            handlePayment(
              response.payload?.result?.amount,
              response.payload?.result?.receipt,
              response.payload?.result?.razor_order_id,
              response.payload?.result?.order_id
            );
          }
        } else {
          setLoading(false)
          console.log("error");
        }
      });
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (amount, receipt, orderId, id) => {
    const res = await loadRazorpayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else {
      setRzLoading(false);
    }

    const options = {
      key: "rzp_live_J2qxjCpTPwFUJI",
      amount: amount,
      currency: "INR",
      name: "DELEEVRO",
      description: receipt,
      order_id: orderId,
      handler: (response) => {
        const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;

        const requestBody = {
          payment_mode: "razor",
          razorpay_order_id: razorpay_order_id,
          payment_status: "success",
          razorpay_payment_id: razorpay_payment_id,
          razorpay_signature: razorpay_signature,
        };

        fetch(`https://deleevro.com/api/user/order/${id}`, {
          method: 'POST',
          headers: {
            'Authorization': localStorage.getItem('device_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        })
          .then((res) => res.json())
          .then((data) => {
            localStorage.removeItem("tip");
            dispatch(clearCart());

            navigate("/order-tracking/" + id);
          })
          .catch((error) => {
            console.error('API Error:', error);
          });
      },
      prefill: {
        name: name,
        email: email,
        contact: mobile,
      },
      theme: {
        color: "#7ac356",
      },
      modal: {
        ondismiss: () => {
          setRzLoading(false);
          setLoading(false);
        }
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", (response) => {
      console.log(response);
    });

    paymentObject.open();
  };

  // const launchRazor = (order, runFunction, successRazor) => {
  //   axios.post('https://apps.deleevro.com/api/process-razorpay', {
  //     amount: parseFloat(order.total).toFixed(2),
  //     id: order.id,
  //     token: localStorage.getItem("device_token")
  //   })
  //     .then((res) => {
  //       if (res.data.razorpay_success) {
  //         // if (!window.ReactNativeWebView) {
  //         const options = {
  //           key: 'rzp_live_mdlFIANCE4L2CH',
  //           amount: parseFloat(order.total).toFixed(2),
  //           name: 'Deleevro',
  //           currency: 'INR',
  //           order_id: res.data.response.id,
  //           payment_capture: 1,
  //           method: {
  //             netbanking: true,
  //             card: true,
  //             wallet: false,
  //             upi: true
  //           },

  //           handler(response) {
  //             console.log("Final Response", response);
  //             runFunction(order.id, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature);
  //             successRazor(order.id);
  //           },
  //           modal: {
  //             ondismiss: function () {
  //               console.log("closed");
  //               runFunction(order.id, null, null, null);
  //               setLoading(false);
  //             },
  //             confirm_close: true
  //           },
  //           prefill: {
  //             name: user?.data?.name,
  //             contact: user?.data?.phone,
  //             email: user?.data?.email,
  //           },

  //         };
  //         const rzp1 = new window.Razorpay(options);
  //         rzp1.open();
  // } else {
  // 	// Its from ReactNativeWebView .. Hence Launching Native RazorPay
  // 	const options = {
  // 		key: 'rzp_live_qiEWGfEpXiaboK',
  // 		amount: parseFloat(order.payable).toFixed(2),
  // 		name: 'Chopze Delivery',
  // 		currency: 'INR',
  // 		order_id: res.data.response.id,
  // 		payment_capture: 1,
  // 		method: {
  // 			netbanking: true,
  // 			card: true,
  // 			wallet: false,
  // 			upi: true
  // 		},
  // 		prefill: {
  // 			name: this.props.user.data.name,
  // 			contact: this.props.user.data.phone,
  // 			email: this.props.user.data.email ? this.props.user.data.email : '',
  // 		},
  // 		modal: {
  // 			ondismiss: function () {
  // 				console.log("closed");
  // 				runFunction(order.id, null, null, null);
  // 			},
  // 			confirm_close: true
  // 		},
  // 	};

  // 	const response = {
  // 		type: 'razor_pay_initiate',
  // 		data: {
  // 			options: options,
  // 			order_id: order.id,
  // 			auth_token: this.props.user.data.auth_token
  // 		}
  // 	};

  // 	window.ReactNativeWebView.postMessage(JSON.stringify(response));

  // }
  //       }
  //     });
  // }

  // const sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
  //   axios.post('https://apps.deleevro.com/api/process-razorpay-state-update', {
  //     id: id,
  //     token: localStorage.getItem("device_token"),
  //     payment_id,
  //     order_id,
  //     signature
  //   });
  // };

  // const successRazor = (id) => {
  //   localStorage.removeItem("tip");
  //   dispatch(clearCart());

  //   navigate("/order-tracking/" + id);
  // }

  const itemCount = () => {
    let count = 0

    if (cartProducts?.items?.length > 0) {
      cartProducts?.items?.map((orderItem) => {
        if (orderItem?.addonProducts?.length > 0) {
          count += orderItem.addonProducts.length;
        } else {
          count += 1;
        }
      })
      return parseInt(count);
    } else {
      return count;
    }
  }

  const loadingLott = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      {/* <!-- header start --> */}
      <header className="section-t-space">
        <div className="custom-container">
          <div className="d-flex align-items-center">
            <div onClick={() => { window.history.back() }}>
              <i className="ri-arrow-left-s-line fs-5"></i>
            </div>
            <h2 className="flex-grow-1 text-center" style={{ fontWeight: '600' }}>Payment Options</h2>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}

      {/* <!-- payment method section start --> */}
      {rzLoading || loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={loadingLott}
            height={100}
            width={100}
          />
        </div>
        :
        <section className="payment method section-lg-b-space">
          <div className="custom-container">
            <ul className="payment-list section-lg-b-space">
              <li className="cart-add-box payment-card-box gap-0 mt-3">
                <div className="payment-detail" onClick={() => { paymentType === 'cod' ? setPaymentType(null) : setPaymentType('cod') }}>
                  <div className="add-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/icons/svg/cash.svg"
                      alt="cash"
                    />
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold">Cash on Delivery</h5>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="cod"
                        checked={paymentType === 'cod' ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li className="cart-add-box payment-card-box gap-0 mt-3">
                <div className="payment-detail" onClick={() => { paymentType === 'razor' ? setPaymentType(null) : setPaymentType('razor') }}>
                  <div className="add-img">
                    <img
                      className="img-fluid img"
                      src="assets/images/icons/svg/mastercard.svg"
                      alt="google-pay"
                    />
                  </div>
                  <div className="add-content">
                    <div>
                      <h5 className="fw-semibold">Online</h5>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="online"

                        checked={paymentType === 'razor' ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              {notselected ?
                <div className="text-center mt-3" style={{ color: 'red' }}>
                  Select payment type
                </div> : null}
            </ul>

            <div class="pay-popup">
              {alert ? (
                <div className="w-100 text-center alert alert-warning alert-dismissible fade show" role="alert">
                  {alert}
                </div>
              ) : (
                <>
                  <div class="price-items">
                    <h3>₹ {totalPrice()}</h3>
                    <h6>{itemCount()} item Added</h6>
                  </div>
                  <div class="btn theme-btn pay-btn mt-0" onClick={(e) => _completeOrder(e)}>Pay Now</div>
                </>
              )}
            </div>
          </div>
        </section>
      }
      {/* <!-- payment method section end --  */}
    </div>
  );
}

export default ManagePayment;