import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SplashScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("device_token") && localStorage.getItem('userLogin') === 'true') {
            navigate("/home")
        }
    }, []);
    
    return (
        <section className="home">
            <video autoPlay muted loop className="cooking-video">
                <source src="assets/images/video.mp4" type="video/mp4" />
            </video>

            <div className="theme-content">
                <div className="custom-container bg-transparent">
                    <img className="img-fluid logo" src="assets/images/logo/logo.png" alt="logo" style={{ width: '50%' }} />
                    <ul className="slogan">
                        <li>Order</li>
                        <li><img src="assets/images/svg/slogan-star.svg" alt="star" /></li>
                        <li>Delivered</li>
                        <li><img src="assets/images/svg/slogan-star.svg" alt="star" /></li>
                        <li>Enjoy</li>
                    </ul>
                    <p>Find and try various foods from here</p>
                    <Link to="/login">
                        <button className="btn theme-btn start-btn w-100">Get Started</button>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default SplashScreen