import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { WEBSITE_API_URL, WEBSITE_API_URL_NEW } from "../../config";

const initialState = {
    store_data: [],
    store_category_data: [],
    category_stores: [],
    category_items: [],
    product_data: [],
    single_product: [],
    isSuccess: false,
    message: "",
    loading: false,
};

export const getSingleStore = createAsyncThunk("store/getSingleStore", async (id) => {
    try {
        const response = await axios.get(WEBSITE_API_URL_NEW + "/restaurants/" + id);
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const getCategoryStores = createAsyncThunk("store/getCategoryStores", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-category-stores", formData);
        if (response.data.success) {
            return response.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const getSingleStoreCategory = createAsyncThunk("store/getSingleStoreCategory", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-single-store-category", formData);
        if (response.data.success) {
            return response.data.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

export const getSingleCategory = createAsyncThunk("store/getSingleCategory", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-single-category", formData);
        if (response.data.success) {
            return response.data;
        }
    } catch (error) {
        console.log("error", error);
        throw error;
    }
}
);

export const getSingleProduct = createAsyncThunk("store/getSingleProduct", async (formData) => {
    try {
        const response = await axios.post(WEBSITE_API_URL + "/get-single-product", formData);
        if (response.data.success) {
            return response.data;
        }
    } catch (error) {
        console.log('error', error);
        throw error;
    }
});

const storeSlice = createSlice({
    name: "store",
    initialState: initialState,
    reducers: {
        storeCurrentTenant: (state, action) => {
            state.tenant = action.payload;
        },
    },
    extraReducers: {
        [getSingleStore.pending]: (state,) => {
            state.loading = true;
        },
        [getSingleStore.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.store_data = payload;
            state.isSuccess = true;
        },
        [getSingleStore.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },


        [getSingleStoreCategory.pending]: (state,) => {
            state.loading = true;
        },
        [getSingleStoreCategory.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.store_category_data = payload;
            state.isSuccess = true;
        },
        [getSingleStoreCategory.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },


        [getCategoryStores.pending]: (state,) => {
            state.loading = true;
        },
        [getCategoryStores.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.category_stores = payload;
            state.isSuccess = true;
        },
        [getCategoryStores.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },


        [getSingleProduct.pending]: (state,) => {
            state.loading = true;
        },
        [getSingleProduct.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.single_product = payload;
            state.isSuccess = true;
        },
        [getSingleProduct.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },

        [getSingleCategory.pending]: (state,) => {
            state.loading = true;
        },
        [getSingleCategory.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.category_items = payload;
            state.isSuccess = true;
        },
        [getSingleCategory.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    },
});


export const { storeCurrentTenant } = storeSlice.actions;

export default storeSlice.reducer;
