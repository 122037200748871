import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewAddress, getEditAddress, updateAddress } from "../../Redux/Store/addressReducer";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Lottie from "react-lottie";
import load from "../../assets/others/track-order-loading.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AddressDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("HOME");
  const [landmark, setLandmark] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState(localStorage.getItem("address") ? localStorage.getItem("address")?.replace('"', "") : null);
  const addresses = useSelector((state) => state.address?.address_data?.result);
  const [defaultAddress, setDefaultAddress] = useState(() => {
    const filteredAddresses = addresses?.filter(cp => cp?.type === type);
    return filteredAddresses && filteredAddresses.length > 0 ? filteredAddresses[0] : null;
  });

  // useEffect(() => {
  //   console.log('====================================');
  //   console.log(defaultAddress);
  //   console.log('====================================');
  // }, [defaultAddress])

  useEffect(() => {
    if (localStorage.getItem("AddressEdit") == "true") {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("device_token"));
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("address_id", localStorage.getItem("address_id"));
      dispatch(getEditAddress(formData)).then((response) => {
        if (response && response.payload && response.payload.success) {
          setAddress(response?.payload?.address?.address)
          setLandmark(response?.payload?.address?.landmark)
          setType(response?.payload?.address?.address_type)
          setLatitude(response?.payload?.address?.latitude)
          setLongitude(response?.payload?.address?.longitude)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    } else {
      setAddress(localStorage.getItem("address") ? localStorage.getItem("address")?.replace('"', "") : null)
      setLoading(false)
    }
  }, []);

  const handleSetDefaultAddress = (e) => {
    e.preventDefault();
    setLoading(true)

    dispatch(addNewAddress({
      map_address: address,
      landmark: landmark,
      latitude: localStorage.getItem("userLat"),
      longitude: localStorage.getItem("userLng"),
      type: type,
    })).then((response) => {
      if (response && response.payload && response.payload?.message == "success") {
        const userSetAddress = {
          latitude: localStorage.getItem("userLat"),
          longitude: localStorage.getItem("userLng"),
          address: address,
          landmark: landmark,
          tag: type,
        };
        localStorage.setItem("address", address);
        localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
        if (localStorage.getItem("AddressEdit") == "true" || localStorage.getItem("AddressBack") == "true") {
          localStorage.removeItem("AddressEdit")
          localStorage.removeItem("AddressBack")
          navigate("/manage-delivery-address")
        } else {
          navigate("/home")
        }
      } else {
        alert(response.payload)
      }
    })
  };

  const handleUpdateAddress = (e) => {
    e.preventDefault();
    setLoading(true)

    dispatch(updateAddress({
      map_address: address,
      landmark: landmark,
      latitude: localStorage.getItem("userLat"),
      longitude: localStorage.getItem("userLng"),
      type: type,
      id: defaultAddress?.id
    })).then((response) => {
      if (response && response.payload && response.payload?.message == "success") {
        if (localStorage.getItem("AddressEdit") == "true") {
          localStorage.removeItem("AddressEdit")
          navigate("/manage-delivery-address")
        } else {
          navigate("/home")
        }
      }
    })
  };

  const defaultOptions = {
    animationData: load,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      {loading ?
        <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
        :
        <div>

          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel">
                {localStorage.getItem("AddressEdit") == "true" ?
                  <Link to="/manage-delivery-address">
                    <i className="ri-arrow-left-s-line"></i>
                  </Link>
                  :
                  <Link to="/set-location">
                    <i className="ri-arrow-left-s-line"></i>
                  </Link>
                }
                <h2>Complete Address</h2>
              </div>
            </div>
          </header>

          <section className="food-filter address-filter">
            <div className="custom-container">
              <h3>Save As</h3>
              <ul className="food-symbol">
                <li>
                  <div className={type === "HOME" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("HOME")}>
                    <i className="ri-home-5-fill light-text"></i>
                    <h5 className="light-text">Home</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "WORK" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("WORK")}>
                    <i className="ri-briefcase-4-fill light-text"></i>
                    <h5 className="light-text">Work</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "HOTEL" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("HOTEL")}>
                    <i className="ri-building-4-fill light-text"></i>
                    <h5 className="light-text">Hotel</h5>
                  </div>
                </li>
                <li>
                  <div className={type === "OTHER" ? "food-types px-2 py-2 active" : "food-types px-2 py-2"} onClick={() => setType("OTHER")}>
                    <i className="ri-map-pin-fill light-text"></i>
                    <h5 className="light-text">Other</h5>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <Form onSubmit={(defaultAddress && defaultAddress?.type && defaultAddress?.type == type) ?
            (e) => { handleUpdateAddress(e) }
            :
            (e) => { handleSetDefaultAddress(e) }}>
            <section className="section-b-space">
              <div className="custom-container">
                <div className="form-group">
                  <label className="form-label fw-semibold">Address</label>
                  <div className="form-input mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label fw-semibold">
                    Nearby Landmark (optional)
                  </label>
                  <div className="form-input mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Landmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </section>
            <div className="position-fixed bottom-0 px-3 w-100 py-2 bg-white">
              <button type="submit" className="btn theme-btn save-address w-100 mt-0">
                Save Address
              </button>
            </div>
          </Form>
        </div>
      }
    </div>
  );
}

export default AddressDetails;
