import React, { useEffect, useState } from "react";
import TopBar from './TopBar';
import Banner from './Banner';
import Categories from './Categories';
import Product from "./Product";
import Brand from "./Brand";
import Restaurants from "./Restaurants";
import Emptysection from "./Emptysection";
import Footer from "./Footer";
import { getAllHomeDatas } from '../../Redux/Store/homeReducer';
import { useDispatch, useSelector } from "react-redux";
import animationData from '../../assets/others/track-order-loading.json';
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { logoutUser } from "../../Redux/Store/userReducer";
import { useNavigate } from "react-router-dom";

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const home = useSelector((state) => state.home?.home_data);
    const [category, setCategory] = useState(null);
    let foodTopBrands = home?.result?.stores?.filter(store => store.top_picked === 1) || null;

    const handleCategory = (childState) => {
        setCategory(childState);
    };

    useEffect(() => {
        if (localStorage.getItem("device_token") && localStorage.getItem('userLogin') != 'true') {
            localStorage.removeItem("user_id")
            localStorage.removeItem("userLogin")
            localStorage.removeItem("auth_token")
            localStorage.removeItem("is_location")
            localStorage.removeItem("address")
            localStorage.removeItem("geoLocation")
            localStorage.removeItem("userSetAddress")
            localStorage.removeItem("userLng")
            localStorage.removeItem("userLat")
            dispatch(logoutUser())
            navigate("/login")
        } else if (localStorage.getItem('userSetAddress') && localStorage.getItem('userLat') && localStorage.getItem('userLng')) {
            dispatch(getAllHomeDatas({ lat: localStorage.getItem('userLat'), long: localStorage.getItem('userLng') })).then((response) => {
                if (response && response.payload && response.payload?.message == 'success') {
                    sessionStorage.setItem('loading', 'done')
                    setLoading(false)
                } else {
                    localStorage.removeItem("user_id")
                    localStorage.removeItem("userLogin")
                    localStorage.removeItem("auth_token")
                    localStorage.removeItem("is_location")
                    localStorage.removeItem("address")
                    localStorage.removeItem("geoLocation")
                    localStorage.removeItem("userSetAddress")
                    localStorage.removeItem("userLng")
                    localStorage.removeItem("userLat")
                    dispatch(logoutUser())
                    navigate("/login")
                }
            })
        } else {
            localStorage.setItem("IsBackHome", true)
            localStorage.setItem("IsAlready", true)
            navigate("/location")
        }
        try {
            Notification.requestPermission();
        } catch (error) {
        }
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div>
            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :
                <div style={{ paddingBottom: '7rem' }}>
                    <TopBar />

                    <Link to="/search">
                        <section className="search-section">
                            <div className="custom-container">
                                <form className="auth-form search-head" target="_blank">
                                    <div className="form-group">
                                        <div className="form-input" style={{ width: '100%' }}>
                                            <input type="search" className="form-control search" id="inputusername" placeholder="Restaurant, item & more" />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </Link>

                    <Banner banners={home?.result?.banners} />
                    <Banner banners={home?.result?.companyBanners} />

                    <div className="custom-container" style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div onClick={() => { setCategory('FOOD'); localStorage.setItem('category', 'FOOD') }}
                            className={`${category == 'FOOD' ? 'home-select-tabs-active' : 'home-select-tabs'}`}
                            style={{ borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px' }}>
                            Food
                        </div>
                        <div onClick={() => { setCategory('GROCERY'); localStorage.setItem('category', 'GROCERY') }}
                            className={`${category == 'GROCERY' ? 'home-select-tabs-active' : 'home-select-tabs'}`}>
                            Grocery
                        </div>
                        <div onClick={() => { setCategory('MEAT'); localStorage.setItem('category', 'MEAT') }}
                            className={`${category == 'MEAT' ? 'home-select-tabs-active' : 'home-select-tabs'}`}
                            style={{ borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}>
                            Meat
                        </div>
                    </div>

                    {(category == 'FOOD' || category == null) ? (
                        <>
                            <Categories foodCategories={home?.result?.businessCategories} />
                            <Product foodRecommended={home?.result?.featuredProducts} />
                            <Brand foodTopBrands={foodTopBrands} />
                            <Restaurants category={category} stores={home?.result?.stores} />
                            <Emptysection />
                        </>
                    ) : category == 'GROCERY' ? (
                        <>
                            {/* <GroceryCategories groceryCategories={home.groceryCategories} /> */}
                            <Restaurants category={category} stores={home?.result?.stores} />
                            <Emptysection />
                        </>
                    ) : category == 'MEAT' ? (
                        <>
                            <Restaurants category={category} stores={home?.result?.stores} />
                            <Emptysection />
                        </>
                    ) : null}
                </div>
            }
            <Footer category={category} changeCategory={handleCategory} />
        </div>
    );
}

export default Home