import { useEffect } from 'react'
import success from '../../../assets/images/success.png';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../../Redux/Store/cartReducer';
import { useNavigate } from "react-router-dom";

function OrderSuccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        dispatch(clearCart())
        setTimeout(() => {
            localStorage.removeItem("tip")
            localStorage.removeItem("scheduledDate")
            localStorage.removeItem("scheduledTime")
            navigate('/order-tracking/' + id)
        }, 3000);
    }, []);

    return (
        <div >
            <div style={{ paddingTop: '9rem' }}>
                <div className="order-success-center px-3">
                    <img src={success} alt='delvro' />
                    <div className='mt-4 font-24 fw-600 order-success-text'>
                        Order Placed Successfully!!
                    </div>
                </div>
            </div>
            <div className="fixed-btn pt-2 pb-4 px-3 bg-white">
                <Link to={"/order-tracking/" + id}>
                    <div className='get-start-button' style={{ marginBottom: '15px' }}>Track Order</div>
                </Link>
                <Link to="/order-history">
                    <div className='get-start-button'>View Orders</div>
                </Link>
            </div>
        </div>
    )
}

export default OrderSuccess;