import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import no_image from '../../../assets/images/rp1.png'

function Product({ foodRecommended }) {

  return (
    <div>
      {foodRecommended?.length > 0 ?
        <section className="section-t-space">
          <div style={{ "backgroundColor": "rgb(122 195 86 / 32%)", "padding": "20px 0px" }}>
            <div className="title px-3">
              <h3 className="mt-0" style={{ "fontSize": "22px", "fontWeight": "700", "color": "#7ac356" }}>Flash Deals</h3>
            </div>
            <div className='px-3' style={{ color: 'grey' }}>Your flash deals are live now</div>

            <Swiper className='px-3' slidesPerView={2.1} spaceBetween={8}>
              {foodRecommended && foodRecommended?.map((item, index) => (
                <SwiperSlide className='products' style={{ "borderRadius": "10px" }} key={index}>
                  <div className="product-box product-box-bg bg-white p-0">
                    <Link to={"/single-store/" + item?.shop_id}>
                      <ProgressiveImage
                        delay={20}
                        src={item?.images[0]?.url}
                        placeholder={no_image}
                      >
                        {(src) => (
                          <img
                            src={src}
                            style={{
                              height: '7rem',
                              width: '100%',
                              objectFit: 'cover',
                              borderRadius: "10px 10px 0px 0px"
                            }}
                            alt="deleevro"
                            className="img-fluid"
                          />
                        )}
                      </ProgressiveImage>
                    </Link>
                    <div className="product-box-detail" style={{ padding: '10px' }}>
                      <h5>{item.name}</h5>
                      <ul className="timing">
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {item?.shop?.name}
                        </span>
                      </ul>
                      <div className="bottom-panel mt-1">
                        {item['prices']['price'] > 0 ? (
                          <>
                            {parseFloat(item?.shop.offer_percentage) > 0 ? (
                              <div className='flex flex-col items-center'>
                                <div className="price me-1" style={{ lineHeight: '14px' }}>
                                  ₹{parseFloat(parseFloat(item['prices']['price']) - parseFloat(item['prices']['price']) * parseFloat(item?.shop.offer_percentage) / 100).toFixed(1)}
                                </div>
                                <div className="red-strike" style={{ fontSize: '13px', }}>
                                  <s style={{ textDecorationColor: 'red' }}>
                                    ₹{parseFloat(item['prices']['price']).toFixed(1)}
                                  </s>
                                </div>
                              </div>
                            ) : parseFloat(item['prices']['orignal_price']) > parseFloat(item['prices']['price']) ? (
                              <div className='flex flex-col items-center'>
                                <div className="price me-1" style={{ lineHeight: '14px' }}>
                                  ₹{parseFloat(item['prices']['price']).toFixed(1)}
                                </div>
                                <div className="red-strike" style={{ fontSize: '13px' }}>
                                  <s style={{ textDecorationColor: 'red' }}>
                                    ₹{parseFloat(item['prices']['orignal_price']).toFixed(1)}
                                  </s>
                                </div>
                              </div>
                            ) : (
                              <div className="price">₹{parseFloat(item['prices']['price']).toFixed(1)}</div>
                            )}
                          </>
                        ) : (
                          <div className="price">Customizable</div>
                        )}
                        {/* {cartProducts?.items?.find((cp) => cp.id === item?.id) !== undefined ? (
                            <div>
                              {cartProducts?.items?.filter((cp) => cp.id === item?.id).map((product) => (
                                <div className="d-flex justify-content-between align-items-center" key={product.id} >
                                  <div className="home-item-remove" onClick={() => {
                                    let newData = Object.assign({}, product, {
                                      quantity: 1,
                                    });
                                    dispatch(removeFromCart(newData));
                                  }}>
                                    <FaMinus />
                                  </div>
                                  <span className='px-1'>
                                    {product.quantity}
                                  </span>
                                  <div className="home-item-add" onClick={() => {
                                    let newData = Object.assign({}, product, {
                                      quantity: 1,
                                    });
                                    dispatch(addToCart(newData));
                                  }} >
                                    <FaPlus />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) :
                            <div className="cart mb-0" onClick={() => {
                              let newData = Object.assign({}, item, {
                                quantity: 1,
                              });
                              dispatch(addToCart(newData))
                            }}>
                              <i className="ri-shopping-cart-2-fill"></i>
                            </div>
                          } */}
                        <div className="cart mb-0">
                          <i className="ri-shopping-cart-2-fill"></i>
                        </div>
                      </div>
                    </div>
                    {parseFloat(item?.shop.offer_percentage) > 0 ?
                      <div style={{ backgroundColor: '#7ac356', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                        Save {parseInt(item?.shop.offer_percentage)}%
                      </div>
                      : parseFloat(item['prices']['orignal_price']) > parseFloat(item['prices']['price']) &&
                      <div style={{ backgroundColor: '#7ac356', borderRadius: '0px 0px 10px 10px', textAlign: 'center', color: '#fff' }}>
                        Save ₹{item['prices']['orignal_price'] - item['prices']['price']}
                      </div>
                    }
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

          </div>
        </section>
        : null}
    </div>
  )
}

export default Product