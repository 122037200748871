import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Lottie from 'react-lottie';
import animationData from '../../assets/others/track-order-loading.json';
import { verifyOtp, userRegister } from '../../Redux/Store/userReducer'
import { Form, FormControl } from "react-bootstrap";
import OtpInput from "react-otp-input";
import ProgressiveImage from "react-progressive-image";
import no_image from '../../assets/images/rp1.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mobileForm, setMobileForm] = useState(true);
  const [otpForm, setOtpForm] = useState(false);
  const [newUserForm, setNewUserForm] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [otpErrorMsg, setOtpErrorMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [genarated, setGenarated] = useState(Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111);

  const handleChanges = (e, type) => {
    setUserDetails({ ...userDetails, [type]: e });
  }

  const mobileform = () => {
    setLoading(false);
    setMobileForm(true);
    setOtpForm(false);
    setNewUserForm(false);
  }

  useEffect(() => {
    if (localStorage.getItem("userLogin")) {
      localStorage.removeItem("firstLogin")
      navigate("/home")
    } else {
      localStorage.setItem("firstLogin", true)
      setLoading(false)
    }
  }, []);

  const _sendOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg('');

    // generateRecaptcha();
    // let appVerifier = window.recaptchaVerifier;
    let phone = '91' + userDetails.mobile;
    const url = 'https://api.msg91.com/api/v5/otp?template_id=66ab117ad6fc053ca976dff2&mobile=' + phone + '&authkey=427005AKmLNEdUzW66aa3eacP1&otp=' + genarated;
    // signInWithPhoneNumber(auth, phone, appVerifier)
    //   .then((confirmationResult) => {
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    // window.confirmationResult = confirmationResult;

    try {
      axios.get(url)
    } catch (error) {
      console.error('Error sending OTP:', error);
    }

    setMobileForm(false)
    setOtpForm(true)
    setLoading(false)
    // }).catch((error) => {
    // Error; SMS not sent
    // console.log(error);

    //   setLoading(false)
    //   setErrorMsg('Something went wrong')
    // });

    // const formData = new FormData();
    // formData.append("mobile", userDetails.mobile);
    // dispatch(sendOtp(formData)).then((response) => {
    //   if (response.payload.success && response.payload.data === "OTPSEND") {
    //     setMobileForm(false)
    //     setOtpForm(true)
    //     setLoading(false)
    //   } else {
    //     setLoading(false)
    //     setErrorMsg(response.payload.message)
    //   }
    // }).catch(error => {
    //   console.error("send OTP failed", error);
    // })
  }

  const _verifyOtp = (e) => {
    e.preventDefault();
    setLoading(true)
    setOtpErrorMsg('')

    // let confirmationResult = window.confirmationResult;
    let otp = userDetails.otp;
    if (otp == genarated) {
      const formData = new FormData();
      formData.append("phone", userDetails.mobile);
      dispatch(verifyOtp(formData)).then((response) => {
        if (response.payload?.message == 'success' && response.payload?.newUser) {
          setOtpErrorMsg('')
          setMobileForm(false)
          setOtpForm(false)
          setNewUserForm(true)
          setLoading(false)
        } else if (response.payload?.message == 'success' && !response.payload?.newUser) {
          if (response.payload && response.payload?.data && response.payload?.data?.defaultAddress) {
            const userSetAddress = {
              latitude: 9.0176,
              longitude: 76.9261,
              address: 'Ernakulam, Kerala',
              landmark: null,
              tag: null,
            };
            localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
            localStorage.setItem("userLogin", true);
            localStorage.setItem("userLat", 9.0176);
            localStorage.setItem("userLng", 76.9261);
            navigate("/home")
          } else {
            localStorage.setItem("userLogin", true);
            navigate("/location")
          }
        } else {
          setOtpErrorMsg('Invalid OTP entered')
          setLoading(false)
        }
      }).catch(error => {
        console.error("send OTP failed", error);
      })
      // User signed in successfully.
      // let user = result.user;
      // console.log(user);
      // alert('User signed in successfully');
      // ...
    } else {
      setOtpErrorMsg('Invalid OTP entered')
      setLoading(false)
    }

    // const formData = new FormData();
    // formData.append("mobile", userDetails.mobile);
    // formData.append("otp", userDetails.otp);
    // dispatch(verifyOtp(formData)).then((response) => {
    //   if (response.payload.success && response.payload.data === "NEWUSER") {
    //     setOtpErrorMsg('')
    //     setMobileForm(false)
    //     setOtpForm(false)
    //     setNewUserForm(true)
    //     setLoading(false)
    //   } else if (response.payload.success) {
    //     if (response.payload && response.payload?.data && response.payload?.data?.defaultAddress) {
    //       const userSetAddress = {
    //         latitude: response.payload?.data?.defaultAddress.latitude,
    //         longitude: response.payload?.data?.defaultAddress.longitude,
    //         address: response.payload?.data?.defaultAddress.address,
    //         landmark: response.payload?.data?.defaultAddress.landmark,
    //         tag: response.payload?.data?.defaultAddress.tag,
    //       };
    //       localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
    //       localStorage.setItem("userLogin", true);
    //       navigate("/home")
    //     } else {
    //       localStorage.setItem("userLogin", true);
    //       navigate("/set-location")
    //     }
    //   } else {
    //     setOtpErrorMsg('Invalid OTP entered')
    //     setLoading(false)
    //   }
    // }).catch(error => {
    //   console.error("send OTP failed", error);
    // })
  }

  const _userRegister = (e) => {
    e.preventDefault();
    setLoading(true)
    setOtpErrorMsg('')
    const formData = new FormData();
    formData.append("phone", userDetails.mobile);
    formData.append("name", userDetails.name);
    formData.append("email", userDetails.email);
    dispatch(userRegister(formData)).then((response) => {
      if (response.payload.success) {
        if (response.payload && response.payload?.data && response.payload?.data?.defaultAddress) {
          const userSetAddress = {
            latitude: response.payload?.data?.defaultAddress.latitude,
            longitude: response.payload?.data?.defaultAddress.longitude,
            address: response.payload?.data?.defaultAddress.address,
            landmark: response.payload?.data?.defaultAddress.landmark,
            tag: response.payload?.data?.defaultAddress.tag,
          };
          localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
          localStorage.setItem("userLogin", true);
          navigate("/home")
        } else {
          localStorage.setItem("userLogin", true);
          navigate("/set-location")
        }
      } else {
        if (response.payload.type && response.payload.type == 'EMAILEXIST') {
          setEmailExist(true)
          setUserDetails({ ...userDetails, ['email']: '' });
          setLoading(false)
          setTimeout(() => {
            setEmailExist(false)
          }, 3000);
        }
      }
    }).catch(error => {
      console.error("send OTP failed", error);
    })
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const starNumber = (num) => {
    let arr = Object.values(num);
    return (
      arr.splice(0, arr.length - 4).fill('*').join('') + arr.splice(-4).join('')
    );
  };

  return (
    <div>
      {loading ? (
        <div style={{ paddingTop: '16rem' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
          />
        </div>
      ) : mobileForm ? (
        <section className="section-b-space pt-0">
          <ProgressiveImage
            delay={20}
            src="assets/images/background/login.png"
            placeholder={no_image}
          >
            {(src) => (
              <img
                src="assets/images/background/login.png"
                className="img-fluid login-img"
                style={{ height: '24rem' }}
                alt="login-img"
              />
            )}
          </ProgressiveImage>
          <div className="custom-container mt-4">
            <div className="auth-form mt-1">
              <h2>Enter your mobile number to get OTP</h2>

              <div className="form-group mt-4">
                <label className="form-label fw-semibold dark-text">
                  Mobile Number
                </label>
                <Form onSubmit={(e) => _sendOtp(e)}>
                  <div className="d-flex gap-3">
                    <div className="dropdown dark-border-gradient">
                      <div
                        className="btn mt-0"
                        style={{
                          backgroundColor: "rgba(var(--box-bg), 1)",
                          color: "rgba(var(--light-text), 1)",
                          borderRadius: "6px",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        +91{" "}
                      </div>
                    </div>
                    <div className="form-input dark-border-gradient">
                      <FormControl
                        type="tel"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter Mobile Number"
                        required
                        minLength="10"
                        maxLength="10"
                        name="mobile"
                        value={userDetails.mobile}
                        onChange={(e) => handleChanges(e.target.value, "mobile")}
                      />
                    </div>
                  </div>
                  <div className='font-12 text-center leading-3 pb-2' style={{ color: 'red' }}>{errorMsg}</div>
                  <button type="submit" className="btn theme-btn w-100 mt-4" >
                    Send OTP
                  </button>
                </Form>
              </div>
            </div>
            <p className="text-center mt-4 pt-4">
              By continue, you agree to our Terms of service Privacy Policy
              Content Policy
            </p>
          </div>
        </section>
      ) : otpForm ? (
        <div>
          <header className="section-t-space">
            <div className="custom-container">
              <div className="header-panel" onClick={() => mobileform()}>
                <i className="ri-arrow-left-s-line"></i>
                <h2>OTP Verification</h2>
              </div>
            </div>
          </header>
          <div className="custom-container">
            <div className="otp-verification">
              <h3>We have sent a verification code to</h3>
              <h3 className="otp-number mt-2">+91 {starNumber(userDetails.mobile)}</h3>
            </div>
            <Form onSubmit={(e) => _verifyOtp(e)}>
              <div className="d-flex align-items-center justify-content-center mt-3 pt-3 mb-2">
                <OtpInput
                  value={userDetails.otp}
                  onChange={(e) => handleChanges(e, 'otp')}
                  numInputs={6}
                  inputType='number'
                  renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    height: "10vw",
                    width: "10vw",
                    border: "none",
                    backgroundColor: "#f5f5f5",
                    outline: "none",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "1.5em",
                    fontWeight: "500",
                  }}
                />
              </div>
              <div className='font-12 text-red-500 text-center leading-3 pb-2'>
                {otpErrorMsg}
              </div>
              <button type="submit" className='btn theme-btn w-100 mt-4' >
                Verify OTP
              </button>
            </Form>
          </div>
        </div>
      ) : newUserForm &&
      <section className="section-b-space pt-0 pb-4 w-100" style={{ minHeight: '100vh' }}>
        <ProgressiveImage
          delay={20}
          src="assets/images/background/login.png"
          placeholder={no_image}
        >
          {(src) => (
            <img
              src="assets/images/background/login.png"
              className="img-fluid login-img"
              style={{ height: '24rem' }}
              alt="login-img"
            />
          )}
        </ProgressiveImage>
        <div className="custom-container mt-4">
          <div className="auth-form mt-1">
            <h2>Register</h2>

            <Form onSubmit={(e) => _userRegister(e)}>
              <div className="mt-3 mb-2" style={{ paddingBottom: '4rem' }}>
                <div className='text-muted mb-2 fw-600 '>
                  Name
                </div>
                <div className="form-input dark-border-gradient">
                  <FormControl
                    type="text"
                    value={userDetails.name}
                    placeholder="Name"
                    required
                    name="name"
                    className="form-control"
                    onChange={(e) =>
                      handleChanges(e.target.value, 'name')
                    }
                  />
                </div>
                <div className='text-muted mb-2 mt-3 fw-600 '>
                  Email
                </div>
                <div className="form-input dark-border-gradient">

                  <FormControl
                    type="email"
                    value={userDetails.email}
                    placeholder="Email"
                    required
                    name="email"
                    className="form-control"
                    onChange={(e) =>
                      handleChanges(e.target.value, 'email')
                    }
                  />
                </div>
                {emailExist &&
                  <div className='text-danger text-center mt-2'>
                    Email ID already exists...
                  </div>
                }
              </div>
              <div className='w-100 bg-white'>
                <button type="submit" className='btn theme-btn mt-3 w-100' >
                  Login
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>
      }
      <div id="recaptcha" style={{ display: 'none' }}></div>
    </div>
  );
}

export default Login;
