
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function FloatCart({ type }) {
    const cartProducts = useSelector((state) => state.cart);

    const totalPrice = () => {
        return "₹" + parseFloat(cartProducts?.totalPayableAmount).toFixed(2)
    }

    const itemCount = () => {
        let count = 0
        if (cartProducts?.items?.length > 0) {
            cartProducts?.items?.map((orderItem) => {
                if (orderItem?.addonProducts?.length > 0) {
                    count += orderItem.addonProducts.length;
                } else {
                    count += 1;
                }
            })
            return parseInt(count);
        } else {
            return count;
        }
    }


    return (
        <>
            {cartProducts?.items?.length > 0 ?
                <div class="cart-popup">
                    <div class="price-items">
                        <h3>{totalPrice()}</h3>
                        <h6>{itemCount()} item Added</h6>
                    </div>
                    <Link to={"/cart"} class="btn theme-btn cart-btn mt-0">
                        View Cart
                    </Link>
                </div>
                : null}
        </>

    )
}

export default FloatCart