import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL } from '../../../config';
import no_image from '../../../assets/images/rp1.png'

function Brand({ foodTopBrands }) {

  return (
    <div>
      {foodTopBrands?.length > 0 ?
        <section className="section-t-space">
          <div className="custom-container">
            <div className="title">
              <h3 className="mt-0">Top Brands</h3>
              {/* <a href="brand-list">See All</a> */}
            </div>

            <Swiper slidesPerView={4.3} spaceBetween={20}>
              {foodTopBrands && foodTopBrands?.map((brand, index) => (
                <SwiperSlide className='brands-logo' key={index}>
                  <Link to={"/single-store/" + brand?.id} className="food-brands">
                    <ProgressiveImage
                      delay={20}
                      src={brand.avatar}
                      placeholder={no_image}
                    >
                      {(src) => (
                        <img
                          src={src}
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            height: '60px',
                            width: '60px'
                          }}
                          className="img-fluid banner-img"
                          alt="deleevro"
                        />
                      )}
                    </ProgressiveImage>
                  </Link>
                  <h4>{brand?.name}</h4>

                  {/* <a href="details" className="food-brands mt-2">
              <img className="img-fluid brand-img" src="assets/images/icons/brand6.png" alt="brand6" />
            </a>
            <h4>Burger King</h4> */}

                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        </section>
        : null
      }
    </div>
  )
}

export default Brand