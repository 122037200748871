import React, { useEffect, useState } from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleProduct } from '../../Redux/Store/storeReducer';
import ProgressiveImage from 'react-progressive-image';
import nonVeg from '../../assets/svg/nonveg.svg'
import veg from '../../assets/svg/veg.svg'
import { addToCart, removeFromCart } from '../../Redux/Store/cartReducer';
// import { FaMinus, FaPlus } from 'react-icons/fa';
import Lottie from 'react-lottie';
import animationData from '../../assets/others/track-order-loading.json';
import { IMAGE_BASE_URL } from '../../config';
import FloatCart from '../FloatCart';
import no_image from '../../assets/images/rp1.png'


function SingleProduct() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    let { id } = useParams();

    const product = useSelector((state) => state?.store?.single_product?.product);
    const cartProducts = useSelector((state) => state.cart);
    useEffect(() => {
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("token", localStorage.getItem("device_token"));
        formData.append("product_id", id);
        dispatch(getSingleProduct(formData)).then((response) => {
            if (response.payload.success) {
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((error) => {
            console.error("Fetching store failed", error);
            setLoading(false);
        });
    }, []);


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div>
            {loading ?
                <div className="flex items-center justify-center" style={{ paddingTop: '15rem' }}>
                    <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                :
                <div>
                    <header class="section-t-space">
                        <div class="custom-container">
                            <div class="d-flex align-items-center pb-2">
                                <div onClick={() => { window.history.back() }}>
                                    <i class="ri-arrow-left-s-line fs-3"></i>
                                </div>
                                <h2 className='flex-grow-1 text-center' style={{ fontWeight: '550' }}>Product</h2>
                            </div>
                        </div>
                    </header>
                    <div class="product-detail-popup">
                        <div class="product-box-modal ratio_40">
                            <div class="product-img">
                                <ProgressiveImage
                                    delay={20}
                                    src={IMAGE_BASE_URL + product.image}
                                    placeholder={no_image}
                                >
                                    {(src) => (
                                        <img
                                            src={src}
                                            className="img-fluid img"
                                            alt="deleevro"
                                            style={{ width: '100%', height: '15rem', borderRadius: '10px', objectFit: 'cover' }} />
                                    )}
                                </ProgressiveImage>

                            </div>
                            <div class="product-content">
                                <div class="product-info">
                                    {product?.food_type === 'non-veg' ?
                                        <img class="img" src={nonVeg} alt="non-veg" />
                                        :
                                        <img class="img" src={veg} alt="veg" />
                                    }
                                    {/* {console.log(product, 'product')} */}
                                    <h5 class="product-name">{product?.name}</h5>
                                    {/* <div class="d-flex align-items-center gap-2 mb-2">
                                <ul class="rating-stars">
                                    <li><i class="ri-star-fill stars"></i></li>
                                    <li><i class="ri-star-fill stars"></i></li>
                                    <li><i class="ri-star-fill stars"></i></li>
                                    <li><i class="ri-star-fill stars"></i></li>
                                    <li><i class="ri-star-fill stars"></i></li>
                                </ul>
                                <h5 class="dark-text">1k+ Rating</h5>
                            </div> */}
                                    {/* <div class="product-price">
                                        <div className="price">₹{product?.selling_price}</div>
                                    </div> */}
                                    <div class="product-price d-flex align-items-center">
                                        {product?.shop.is_offer && parseFloat(product?.shop.offer_percentage) > 0 ? (
                                            <>
                                                <div className="text-black me-1" style={{ fontWeight: '600', fontSize: '18px' }}>
                                                    ₹{parseFloat(parseFloat(product.selling_price) - parseFloat(product.selling_price) * parseFloat(product?.shop.offer_percentage) / 100).toFixed(1)}
                                                </div>
                                                <div className="red-strike" style={{ fontSize: '16px' }}>
                                                    <s style={{ textDecorationColor: 'red' }}> ₹{parseFloat(product.selling_price).toFixed(1)}</s> </div>
                                            </>
                                        ) : parseFloat(product.price) > parseFloat(product.selling_price) ? (
                                            <>
                                                <div className="text-black " style={{ fontWeight: '600' }}>
                                                    ₹{parseFloat(product.selling_price).toFixed(1)}
                                                </div>
                                                <div className="red-strike" style={{ fontSize: '13px' }}>
                                                    <s style={{ textDecorationColor: 'red' }}> ₹{parseFloat(product.price).toFixed(1)}
                                                    </s> </div>
                                            </>
                                        ) : (
                                            <div className="price">₹{parseFloat(product.selling_price).toFixed(1)}</div>
                                        )}
                                    </div>
                                </div>
                                {product?.out_of_stock == 'YES' ? (
                                    <div className='text-danger'>Out of Stock</div>
                                ) : (
                                    <>
                                        {cartProducts?.items?.find((cp) => cp.id === product?.id) !== undefined ? (
                                            <div>
                                                {cartProducts?.items?.filter((cp) => cp.id === product?.id).map((item) => (
                                                    <div className="plus-minus" key={item.id} >
                                                        <div className="" onClick={() => {
                                                            let newData = Object.assign({}, product, {
                                                                quantity: 1,
                                                            });
                                                            dispatch(removeFromCart(newData));
                                                        }}>
                                                            <i class="ri-subtract-line sub"></i>
                                                        </div>
                                                        <span className='px-3' style={{ color: '#7ac376' }}>
                                                            {item.quantity}
                                                        </span>
                                                        <div className="" onClick={() => {
                                                            let newData = Object.assign({}, item, {
                                                                quantity: 1,
                                                            });
                                                            dispatch(addToCart(newData));
                                                        }} >
                                                            <i class="ri-add-line add"></i>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) :
                                            <div className="plus-minus" style={{ color: '#7ac376' }} onClick={() => {
                                                let newData = Object.assign({}, product, {
                                                    quantity: 1,
                                                });
                                                dispatch(addToCart(newData))
                                            }}>
                                                <i className="ri-shopping-cart-2-fill me-2"></i>Add
                                            </div>
                                        }
                                    </>
                                )}
                            </div>
                            <p>{product?.description}</p>
                            {/* <ul class="ingredient-list">
                        <li>2-3 pounds beef roast (such as chuck, eye of round, etc.)</li>
                        <li>1 tablespoon ground chipotle chilis</li>
                        <li>2 tablespoons ground ancho chilis</li>
                        <li>1 teaspoon ground cumin</li>
                        <li>1 tablespoon oregano</li>
                    </ul> */}
                            <FloatCart type={true} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleProduct